<template>
  <div class="home-view">
    <div class="top-info">
      <div>Gabriela Těthalová</div>
      <router-link class="link" to="/about">info</router-link>
    </div>
    <image-carousel />
  </div>
</template>

<script>
import ImageCarousel from "../components/ImageCarousel.vue";

export default {
  name: "HomeView",
  components: { ImageCarousel },
};
</script>

<style lang="scss" scoped>
.home-view {
  height: 100%;
  width: 100%;
  position: relative;

  .top-info {
    line-height: 1.075em;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    z-index: 2;
  }

  .link {
    text-decoration: unset;
    color: inherit;
  }

  @media screen and (max-width: 1025px) {
    .top-info {
      font-size: 65px;
      line-height: 69px;
    }
  }
}
</style>
