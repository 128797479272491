<template>
  <div class="image-carouser">
    <swiper
      ref="imageSwiper"
      class="mage-swiper"
      :navigation="false"
      :loop="true"
      :modules="modules"
      :breakpoints="breakpoints"
      @touchEnd="onTouchMove"
      @click="onSwiperClick"
      @swiper="onSwiper"
    >
      <swiper-slide v-for="(slide, slideIndex) in slides" :key="slide.name">
        <div :class="`${!slide.fullSize ? 'image-container' : ''}`">
          <img
            :ref="`image-${slideIndex}`"
            :alt="`image-${slideIndex}`"
            :src="require(`@/assets/images/${slide.src}`)"
            :class="[
              `${slide.fullSize ? 'image-full' : ''}`,
              `${isImageWide(slideIndex) ? 'image-wide' : 'image-tall'}`,
            ]"
            @load="imgloaded"
          />
        </div>
      </swiper-slide>
    </swiper>
    <div
      class="info-wrapper"
      :style="{ height: !isDesktop && `${spaceBellow}px` }"
    >
      <div class="counter">{{ counter }}</div>
      <div v-if="activeArtworkName" class="artwork-name">
        {{ activeArtworkName }}
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {
  drawTextTail,
  initCanvas,
  eraseTextTrail,
  getCanvasProjects,
} from "../utils/textTail.js";

export default {
  name: "ImageCarousel",
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => {
    return {
      swiper: null,
      speed: 800,
      isImageLoaded: false,
      breakpoints: {
        1025: {
          shortSwipes: false,
        },
      },
      slides: [
        {
          name: "Jindřich Chalupecký Award, PLATO Ostrava, 2023 - Exhibition View",
          src: "01.jpg",
          fullSize: true,
        },
        {
          name: "Temple of the Moon, 200 x 190 cm, 2023",
          src: "02.jpg",
          fullSize: false,
        },
        {
          name: "Jindřich Chalupecký Award, PLATO Ostrava, 2023 - Space-time Spillover",
          src: "03.jpg",
          fullSize: true,
        },
        {
          name: "Emotional Body, 150 x 130 cm, 2023",
          src: "04.jpg",
          fullSize: false,
        },
        {
          name: "Jindřich Chalupecký Award, PLATO Ostrava, 2023 - Reverse Mantra (Musical Collaboration)",
          src: "05.jpg",
          fullSize: false,
        },
        {
          name: "Gazing into space-time, 147 x 142 cm, 2022",
          src: "06.jpg",
          fullSize: false,
        },
        {
          name: "Jindřich Chalupecký Award, PLATO Ostrava, 2023 - Exhibition View",
          src: "07.jpg",
          fullSize: false,
        },
        {
          name: "Skin Shedding, 200 x 250 cm, 2022",
          src: "08.jpg",
          fullSize: true,
        },
        {
          name: "Timer, 160 x 150 cm, 2023",
          src: "09.jpg",
          fullSize: false,
        },
        {
          name: "Burning an eternal flame (Annunciation), 180 x 150 cm, 2022",
          src: "10.jpg",
          fullSize: false,
        },
        {
          name: "Jindřich Chalupecký Award, PLATO Ostrava, 2023 - Exhibition View",
          src: "11.jpg",
          fullSize: true,
        },
        {
          name: "Jindřich Chalupecký Award, PLATO Ostrava, 2023 - Exhibition View",
          src: "12.jpg",
          fullSize: false,
        },
      ],
    };
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
  beforeUnmount() {
    eraseTextTrail();
  },

  computed: {
    activeIndex() {
      return this.swiper?.realIndex;
    },
    activeArtworkName() {
      return this.slides[this.activeIndex]?.name ?? null;
    },
    counter() {
      return `${this.activeIndex + 1}/${this.slides.length}`;
    },
    isDesktop() {
      return window.innerWidth > 1025;
    },
    spaceBellow() {
      const innerHeight = window.innerHeight;
      if (!this.isImageLoaded) {
        return innerHeight / 4;
      }
      const activeImageRef = `image-${this.activeIndex}`;
      const refArray = this.$refs[activeImageRef] ?? [];
      const imageHeight = refArray?.[0]?.clientHeight;
      return (innerHeight - imageHeight) / 2;
    },
  },

  watch: {
    activeIndex: {
      handler: function (value) {
        if (!getCanvasProjects().length) {
          initCanvas();
        }
        const currentName = this.slides[value].name;
        drawTextTail(currentName);
      },
      deep: true,
    },
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    onTouchMove() {
      if (this.isDesktop) {
        this.swiper?.slideNext(this.speed);
      }
    },
    onSwiperClick() {
      this.swiper?.slideNext(this.speed);
    },
    getImageSrc(name) {
      return `@/assets/${name}`;
    },
    isImageWide(index) {
      const activeImageRef = `image-${index}`;
      const refArray = this.$refs[activeImageRef] ?? [];
      const isWide = refArray?.[0]?.naturalWidth > refArray?.[0]?.naturalHeight;
      return isWide;
    },
    imgloaded() {
      const activeImageRef = `image-${this.activeIndex}`;
      const refArray = this.$refs[activeImageRef] ?? [];
      if (refArray?.[0]?.clientHeight > 0) {
        this.isImageLoaded = true;
      }
    },
  },
};
</script>
<style lang="scss">
.image-carouser {
  height: 100%;
  width: 100vw;

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .image-wide {
      width: auto;
      height: auto;
      max-width: 60%;
      max-height: 80%;
    }

    .image-tall {
      height: auto;
      width: auto;
      max-height: 80%;
      max-width: 60%;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
    max-height: 100vh;

    .swiper-wrapper {
      max-height: 100vh;
    }

    .swiper-slide {
      height: auto;
    }
  }

  .image-full {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .info-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 5px;
    right: 5px;
    left: 5px;
    z-index: 2;

    .counter {
      text-align: right;
    }

    .artwork-name {
      display: none;
    }
  }

  @media screen and (max-width: 1025px) {
    .image-container {
      .image-wide {
        // width: auto;
        // height: auto;
        max-width: 85%;
        max-height: 45%;
      }

      .image-tall {
        // height: auto;
        // width: auto;
        max-height: 45vh;
        max-width: 85%;
      }
    }

    .image-full {
      object-fit: contain;
    }

    .info-wrapper {
      font-size: 65px;
      line-height: 69px;
      padding-bottom: 0px;
      height: 280px;
      justify-content: center;

      .counter {
        text-align: center;
      }

      .artwork-name {
        display: block;
      }
    }
  }
}
</style>
