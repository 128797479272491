<template>
  <!-- <div id="app"> -->
  <!-- </div> -->
  <router-view />
</template>

<script>
export default {
  name: "app",
};
</script>

<style>
@font-face {
  font-family: "neue-haas-unica";
  /* src: url("https://use.typekit.net/af/d7f1e9/00000000000000007735bb2a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/d7f1e9/00000000000000007735bb2a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/d7f1e9/00000000000000007735bb2a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype"); */
  src: url("@/assets/fonts/d.woff")
    format("woff");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

#app {
  font-family: "neue-haas-unica", sans-serif;
  /* free alternative */
  /* font-family: "Helvetica", sans-serif; */
  font-size: 28px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;

  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.canvas {
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 3;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  align-items: center;
  justify-content: center;
  display: flex;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

 @media screen and (max-width: 1025px) {
   .canvas {
     visibility: hidden;
   }
 }
</style>
