/* eslint-disable */
import paper from "paper";

const initCanvas = () => {
  paper.install(window);
  paper.setup("canvas");
};

const getCanvasProjects = () => {
  return paper.projects
}

const eraseTextTrail = () => {
  // there is always only one tool
  if (paper.tool) {
    paper.tool.remove();
  }
  paper.project.clear();
};

// this is not nice. Better make this util as a class..
const drawTextTail = (word) => {
  var tool1;
  var path = new paper.Path();
  var points = word.length;
  var length = word.length;
  // var vw = window.innerWidth/100;

  // TODO: keep this?
  if (paper.tool) {
    paper.tool.remove();
  }
  paper.project.clear();

  var createPointText = function (str, style) {
    var text = new paper.PointText();
    text.content = str;
    text.fillColor = "inherit";
    if (style) {
      if (style.font) text.font = style.font;
      if (style.fontFamily) text.fontFamily = style.fontFamily;
      if (style.fontSize) text.fontSize = style.fontSize;
      if (style.fontWeight) text.fontWeight = style.fontWeight;
    }
    return text;
  };

  var start = new paper.Point(Math.random() * 100, Math.random() * 100);
  for (var i = 0; i < points; i++) {
    path.add(new paper.Point(i * length + start.x, 0 + start.y));
    path.smooth();
  }

  var createAlignedText = function (str, path, style) {
    if (str && str.length > 0 && path) {
      // create PointText object for each glyph
      var glyphTexts = [];
      for (var i = 0; i < str.length; i++) {
        glyphTexts[i] = createPointText(str.substring(i, i + 1), style);
        glyphTexts[i].justification = "center";
      }
      // for each glyph find center xOffset
      var xOffsets = [0];
      for (var i = 1; i < str.length; i++) {
        var pairText = createPointText(str.substring(i - 1, i + 1), style);
        pairText.remove();
        xOffsets[i] =
          xOffsets[i - 1] +
          pairText.bounds.width -
          glyphTexts[i - 1].bounds.width / 2 -
          glyphTexts[i].bounds.width / 2;
      }
      // set point for each glyph and rotate glyph aorund the point
      for (var i = 0; i < str.length; i++) {
        var centerOffs = xOffsets[i];
        if (path.length < centerOffs) {
          if (path.closed) {
            centerOffs = centerOffs % path.length;
          } else {
            centerOffs = undefined;
          }
        }
        if (centerOffs === undefined) {
          glyphTexts[i].remove();
        } else {
          var pathPoint = path.getPointAt(centerOffs);
          glyphTexts[i].point = pathPoint;
          var tan = path.getTangentAt(centerOffs);
          glyphTexts[i].rotate(tan.angle, pathPoint);
        }
      }
    }
  };

  
  if (paper.tool) {
    paper.tool.remove();
  }
  tool1 = new paper.Tool();
 
  tool1.onMouseMove = function (event) {
    paper.project.clear();

    path.firstSegment.point = event.point;
    for (var i = 0; i < points - 1; i++) {
      var segment = path.segments[i];
      var nextSegment = segment.next;
      var vector = new paper.Point(
        segment.point.x - nextSegment.point.x,
        segment.point.y - nextSegment.point.y
      );
      vector.length = length;
      nextSegment.point = new paper.Point(
        segment.point.x - vector.x,
        segment.point.y - vector.y
      );
    }

    path.smooth();
    path.strokeColor = "inherit";
    path.smooth({ type: "continuous" });

    //  createAlignedText(word, path, {
    //    fontSize: vw,
    //   fontFamily: "Helvetica",
    //  });

    createAlignedText(word, path, {
      fontSize: 28,
      fontFamily: "Helvetica",
    });

    path.fullySelected = true;
  };
};

export { drawTextTail, initCanvas, eraseTextTrail, getCanvasProjects };
